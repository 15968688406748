import axios from "axios";
import { store } from "../../redux/store";
// export const URL = "https://app-beta.taakkad.com/";
export const URL = "https://mitcv-back.appssquare.com";

const state = store.getState();
const DefaultCompany = state.companyReducer?.selectedCompany;

const instance = axios.create({
  baseURL: `${URL}/api/dashboard`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("mitcvAdminToken")}`,
    "X-Language": localStorage.getItem("lang") ?? "en",
  },
});

instance.interceptors.request.use((req) => {
  return req;
});

instance.interceptors.response.use(
  (res) => {
    return res;
  },

  (err) => {
    if (
      err &&
      err.response &&
      err.response.status == 403 &&
      err.response.config.url != "/login"
    ) {
      // window.localStorage.removeItem("mitcvAdminToken");
      // window.location.replace("/");
    } else if (
      err.response.status == 401 &&
      err.response.config.url != "/login"
    ) {
      // window.localStorage.removeItem("mitcvAdminToken");
      // window.location.replace("/");
    } else {
      return Promise.reject(err);
    }
  }
);

export default instance;
